import { useEffect } from 'react';

const MarkerMapbox = props => {
  const { map, listing, onListingClicked } = props;
  const { geolocation } = listing?.attributes || {};
  const { lat, lng } = geolocation || {};

  useEffect(() => {
    if (!map) return;
    const marker = new window.mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);

    // Attach click event listener
    marker.getElement().addEventListener('click', () => {
      if (onListingClicked) {
        onListingClicked(listing); // Pass coordinates to click handler
      }
    });

    return () => {
      marker.getElement().removeEventListener('click', () => {}); // Cleanup event listener
      marker.remove(); // Remove marker on unmount
    };
  }, [map, lng, lat, onListingClicked]);

  return null; // No need to render anything in the DOM
};

export default MarkerMapbox;
